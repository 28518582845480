import { Link } from "gatsby"
import React from "react"
import "./pagination.scss"
export const Pagination = ({ currentPage, pageCount, lang }) => {
  let path = lang==="vi" ? "/vi/blog" : "/blog"
  let prevDisable = currentPage <= 1 ? "disable" : ""
  let nextDisable = currentPage >= pageCount ? "disable" : ""
  return (
    <nav className="pagination">
      <Link
        title="Go to previous page"
        to={path}
        className={`pagination-btn ${prevDisable}`}
      >
        <div className="prev arrow"></div>
      </Link>

      {Array.from({ length: pageCount }, (_, i) => (
        <Link
          activeClassName="active"
          key={`pagination-number${i + 1}`}
          to={`${i === 0 ? path : `${path}/${i + 1}`}`}
        >
          {i + 1}
        </Link>
      ))}
      <Link
        title="Go to next page"
        to={`${path}/${currentPage + 1}`}
        className={`pagination-btn ${nextDisable}`}
      >
        <div className="next arrow"></div>
      </Link>
    </nav>
  )
}
