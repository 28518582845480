import React, { useState } from "react"
import {
  Layout,
  Seo,
  Hero,
  DropdownCat,
  FormSendMailchimp,
  Banner,
  ErrorBoundary,
  MindBodyPage,
} from "../../index.js"
import { graphql, Link } from "gatsby"

import { Pagination } from "../components/Pagination/Pagination .jsx"
import BackgroundImage from "gatsby-background-image"
import "../style/_blog-landing.scss"
import HeroImage from "../images/Blog-hero-optimised.jpg"
import Button from "../components/Button/Button.jsx"
let defaultFeatured = {
  "base64": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHOVVqKkA//xAAbEAABBAMAAAAAAAAAAAAAAAASAAECAxARIf/aAAgBAQABBQKNbCLIYLfMf//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAADAQEAAAAAAAAAAAAAAAAAATEREP/aAAgBAQAGPwLW6Upnf//EABoQAAIDAQEAAAAAAAAAAAAAAAABESExQXH/2gAIAQEAAT8hgKAc+V6JhdeUKFwas//aAAwDAQACAAMAAAAQ8A//xAAWEQEBAQAAAAAAAAAAAAAAAAAAAXH/2gAIAQMBAT8QjT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBX/8QAHRABAQABBAMAAAAAAAAAAAAAAREAITFRYUGB8f/aAAgBAQABPxBEMiBOO800JYx5w7e+5jICKsO/mLco4uBWmf/Z",
  "aspectRatio": 1.5037593984962405,
  "src": "/static/beb0b1657ff2c37c750681d57e500cbe/a1eb1/Hustle-Thao-Dien-4433-scaled.jpg",
  "srcSet": "/static/beb0b1657ff2c37c750681d57e500cbe/4fa6c/Hustle-Thao-Dien-4433-scaled.jpg 200w,\n/static/beb0b1657ff2c37c750681d57e500cbe/64b17/Hustle-Thao-Dien-4433-scaled.jpg 400w,\n/static/beb0b1657ff2c37c750681d57e500cbe/a1eb1/Hustle-Thao-Dien-4433-scaled.jpg 800w,\n/static/beb0b1657ff2c37c750681d57e500cbe/a6c62/Hustle-Thao-Dien-4433-scaled.jpg 1200w,\n/static/beb0b1657ff2c37c750681d57e500cbe/70ff9/Hustle-Thao-Dien-4433-scaled.jpg 1600w,\n/static/beb0b1657ff2c37c750681d57e500cbe/6ce57/Hustle-Thao-Dien-4433-scaled.jpg 2560w",
  "srcWebp": "/static/beb0b1657ff2c37c750681d57e500cbe/b0751/Hustle-Thao-Dien-4433-scaled.webp",
  "srcSetWebp": "/static/beb0b1657ff2c37c750681d57e500cbe/9e195/Hustle-Thao-Dien-4433-scaled.webp 200w,\n/static/beb0b1657ff2c37c750681d57e500cbe/40a1d/Hustle-Thao-Dien-4433-scaled.webp 400w,\n/static/beb0b1657ff2c37c750681d57e500cbe/b0751/Hustle-Thao-Dien-4433-scaled.webp 800w,\n/static/beb0b1657ff2c37c750681d57e500cbe/a7c53/Hustle-Thao-Dien-4433-scaled.webp 1200w,\n/static/beb0b1657ff2c37c750681d57e500cbe/80926/Hustle-Thao-Dien-4433-scaled.webp 1600w,\n/static/beb0b1657ff2c37c750681d57e500cbe/385a5/Hustle-Thao-Dien-4433-scaled.webp 2560w",
  "sizes": "(max-width: 800px) 100vw, 800px"
}
const BlogLanding = ({ data, pageContext }) => {
  let postLength = data.allWpPost.nodes.length
  let gridSystem = `grid${postLength}`
  let backgroundClass = postLength <= 4 ? "halfBackground" : ""
  let form = data?.wpBlogLanding?.blocks[0]?.formBlock
  const categories = data.allWpCategory?.nodes?.filter(
    category => category.posts.nodes.length !== 0
  )

  // handle toggle form
  const [toggle, setToggle] = useState(false)
  const onClick = () => {
    const healCode = document.querySelector("healcode-widget")
    if (healCode) {
      healCode.style.display = toggle ? "none" : "block"
    }
    setToggle(!toggle)
  }

  return (
    <Layout>
      <Seo title="Blog - Hustle" description="Welcome to Hustle Vietnam" />

      <Banner onClick={onClick} hidden={toggle} />
      <Hero
        staticImg={HeroImage}
        heroTitle={{ normalTitle: "HUSTLE", strokeTitle: "BLOG" }}
      />
      {toggle && (
        <ErrorBoundary>
          <MindBodyPage toggleForm={onClick} toggle={toggle} />
        </ErrorBoundary>
      )}
      <DropdownCat categories={categories} currentCategory={"FITNESS"} />
      <section
        className={`block-posts-list ${backgroundClass}`}
        style={{ background: `${postLength == 0 ? "transparent" : ""}` }}
      >
        {/* first-block */}
        <div className="white-background">
          <div className="container">
            {postLength === 0 ? (
              <div className="blog-navigation">
                <p>
                  Hiện không có bài blog Tiếng Việt nào, đi đến phiên bản Tiếng
                  Anh?
                </p>{" "}
                <Button text="go to blog" link="blog" />
              </div>
            ) : (
              ""
            )}
            <div className={`blog-post-wrapper ${gridSystem}`}>
              {data.allWpPost.nodes.map((node, index) => {
                return (
                <BackgroundImage
                  className={`post-item post-${index + 1}`}
                  fluid={
                    node?.featuredImage?.node?.localFile?.childImageSharp?.fluid || defaultFeatured
                  }
                >
                  <div className="overlay"></div>
                  <Link to={`/${node.slug}`}>
                    <div className="post-item-content">
                      <div className="blog-title">
                        <h3>{node.title}</h3>
                      </div>
                      <div
                        className="blog-excerpt"
                        dangerouslySetInnerHTML={{ __html: node.excerpt }}
                      ></div>
                      <div className="view-icon">
                        <span className="view"></span>
                      </div>
                    </div>
                  </Link>
                </BackgroundImage>
              )})}
            </div>
            {postLength > 0 && (
              <Pagination
                pageCount={pageContext.pageCount}
                currentPage={pageContext.currentPage}
                lang={pageContext.lang === 'vi' ? "vi": ""}
              />
            )}
          </div>
        </div>
      </section>
      <FormSendMailchimp
        form={form}
        formClassName="blog-landing-custom-form"
        buttonClassName="blog-landing-custom-button"
        lang={pageContext.lang}
      />
    </Layout>
  )
}
export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!, $lang: String!) {
    allWpPost(
      sort: { fields: [date] order: DESC}
      limit: $limit
      skip: $skip
      filter: { language: { slug: { eq: $lang } } }
    ) {
      nodes {
        title
        excerpt
        slug
        featuredImage {
          node {
            slug
            localFile {
              childImageSharp {
                fluid(quality: 90, fit: COVER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allWpCategory {
      nodes {
        name
        slug
        posts {
          nodes {
            title
          }
        }
      }
    }
    wpBlogLanding(language: { slug: { eq: $lang } }) {
      blocks {
        ... on WpAcfSignupFormBlock {
          formBlock {
            signupTitle
            signupSubtitle
            signupButton
          }
        }
      }
    }
  }
`
export default BlogLanding
